import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Image from "../../../components/image"
import { FaChevronRight } from "react-icons/fa"
import moment from 'moment'
import Helmet from "react-helmet"
import ListTop3 from "../components/listTop3"

const sizePage = ({ data, location }) => {
	const size = data.sizesDailyYaml
	const sizeStr = '' 
	const itemStr = '日用品'
	const unitStr = '100ml'
	const category = 'daily'
	const categoryIndex = '/daily/softener_refill_all_all'
	let description = `${size.description}${sizeStr} ${itemStr}${unitStr}あたりの最安値！`
	let preTitle = ``

  // allContentfulItemsを100ml当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})	

	// allTypesDailyYamlを100ml当たりの価格が安い順に並び替える
	let orderAllTypesYaml = data.allTypesDailyYaml.edges.map(edge => {
		const item = data.allContentfulItems.edges.find(n => {
			return n.node.sizeId === size.id && n.node.typeId === edge.node.id
		})
		if(item) {
			if(item.node.itemList.length){
				edge['per'] = item.node.itemList[0].per	
			}
		}
		return edge
	}).sort(function(a,b){
		return a.per - b.per
	})

	const cheap_item = data.allContentfulItems.edges.find(edge => {
		return edge.node.sizeId === size.id
	})
	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment(cheap_item.node.updatedAt).format('YYYY年M月D日')

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": itemStr,
			"item": "https://hula-la.com" + categoryIndex
		},{
			"@type": "ListItem",
			"position": 3,
			"name": size.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};
	
	return (
  <Layout>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
			 	<h2 id="size" className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{itemStr} {size.description}{sizeStr} {unitStr}あたりの最安値</h2>
				<p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>{unitStr}あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
				<ListTop3 typeValue="all" sizeValue={size.id} brandValue="all" shopValue="all" articleUrl={`https://hula-la.com/${size.id}.html`} articleTitle={`${itemStr} ${size.description}`} />
				{orderAllTypesYaml.map(edge => {
					let cnt = 0
					const type = edge.node
					const item = data.allContentfulItems.edges.find(n => {
						return n.node.typeId === type.id
					})
					if(!item) return <></>
					description += `${type.description}タイプ `
					// allBrandsDailyYamlを100ml当たりの価格が安い順に並び替える
					let orderAllBrandsYaml = data.allBrandsDailyYaml.edges.map(edge => {
						const item = data.allContentfulItems.edges.find(n => {
							return n.node.typeId === type.id && n.node.brandId === edge.node.id
						})
						if(item) {
							if(item.node.itemList.length){
								edge['per'] = item.node.itemList[0].per	
							}
						}
						return edge
					}).sort(function(a,b){
						return a.per - b.per
					})
					return (
						<>
						<div className="content" style={{marginTop: `1.3rem`}}>
							<h3 className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>[{type.description}] 価格比較<small> ({update}更新)</small></h3>
							<div className="columns is-multiline is-gapless">
								{orderAllBrandsYaml.map(edge => {
			            const brand = edge.node
									const item = data.allContentfulItems.edges.find(n => {
										return n.node.typeId === type.id && n.node.brandId === brand.id && n.node.itemList.length !== 0
									})
									if(!item) return <></>
									const filename = `${brand.id}_${type.id}_${size.id}_${item.node.subbrandId}.jpg`
									if(!item.node.itemList.length) return <></>
									description += `${brand.description}${item.node.itemList[0].per.toFixed(1)}円 `
									preTitle += preTitle === `` ? `${item.node.itemList[0].per.toFixed(1)}円/${unitStr}` : ``
									cnt += 1
									const order = cnt
									const subbrand = getSubbrand(data, item.node.brandId, item.node.subbrandId)
									const subbrandDetail = subbrand.types.find(n => {
										return n.type_id === type.id
									}).sizes.find(n => {
										return n.size_id === size.id
									})
									const pack = item.node.itemList[0].num/subbrandDetail.num
									const num = `${subbrandDetail.num}${subbrandDetail.unit}×${pack}=${item.node.itemList[0].num}${subbrandDetail.unit}`
									const perunit = `100${subbrandDetail.unit}`
									return (
										<div className="column is-half">
											<Link to={`/${category}/${brand.id}_${type.id}_${size.id}.html`}>
												<div className="card">
													<div className="card-content" style={{padding: `0.8rem`}}>
														<div className="media">
															<div className="media-left">
																<div>
																	<span>{order}</span><span style={{fontSize:`0.5rem`}}>位</span>
																</div>
																<Image filename={filename} alt={`${brand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<div className="media-content">
																<div className="content">
																	<p>
																		<span style={{fontSize:`0.7rem`}}><strong>{brand.description}</strong></span>
																		<br />
																		<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/{perunit}</span> ～</span>
																		<br />
																		<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
																	</p>
																</div>
															</div>
															<div className="media-right">
																<span className="icon" style={{height: `2.5em`}}>
																	<FaChevronRight />
																</span>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
						<br></br>
						</>
					)
				})}
			</>
		<Seo title={`${itemStr} ${size.description}${sizeStr}`} description={description} canonical={location.pathname} />
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`${categoryIndex}`}>{itemStr}</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{size.description}</Link></li>
			</ul>
		</nav>
  </Layout>
	)
}

export const query = graphql`
	query($size_id: String!) {
		sizesDailyYaml(id: { eq: $size_id}) {
			id
			description
		}
		allTypesDailyYaml {
			edges {
				node {
					id
					description
				}
			}
		}
		allBrandsDailyYaml {
			edges {
				node {
					id
					description
				}
			}
		}
		allContentfulItems(filter: {sizeId: { eq: $size_id}}) {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
				}
			}
		}
		allSubbrandsDailyYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							num	
							unit	
						}
					}
				}
			}
		}  
	}
`

export default sizePage

function getSubbrand(tempData, id, subid){
  const ret = tempData.allSubbrandsDailyYaml.edges.find(edge => {
    return ( edge.node.brand_id === id ) && ( edge.node.subbrand_id === subid )
  })
  return ret.node
}
